.Container {
  margin: 0px 70px;
}

.ContainerEditProfil {
  margin: 0px 210px;
}

.ContainerEditProgramProfil {
  margin: 0px 100px;
}

body {
  background-image: url(../../img/backgrounApik.svg);
  width: 100%;
  height: auto;
}

.list {
  width: 250;
}

.fullList {
  width: auto;
}

.layerKiri {
  width: 10%;
  height: 100%;
  background-color: #00a6a6 !important;
  position: absolute !important;
  display: block;
  left: 0px;
  top: 0px !important;
  z-index: 3 !important;
  margin-right: 10px !important;
}

.buttonLayer {
  left: 135px;
  top: 80px !important;
  background-color: #00a6a6 !important;
  color: #ffffff !important;
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.Link {
  display: flex;
  font-size: 13px !important;
  color: #ff6b09 !important;
  font-weight: bold;
  cursor: pointer;
}

.adaptationOtherContainer {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: -11px;
  margin-right: 16px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.IconBreadcrumbs {
  font-size: 13px !important;
  margin-top: 3px !important;
}

/* Dashboard */

.h4 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20;
}

.mapContainer {
  height: 100vh;
  width: calc(100vw);
  display: inline-block;
  overflow: hidden;
  transition: all 0.3s linear;
}

.Maphalf {
  /* position: relative !important; */
  height: 100%;
  width: calc(100% - 400px);
  display: inline-block !important;
  transition: all 0.3s linear;
  z-index: 1;
}

.Mapfullhalf {
  /* position: relative !important; */
  height: 100%;
  width: calc(100% - 600px);
  display: inline-block !important;
  transition: all 0.3s linear;
  z-index: 1;
}

.Map {
  height: 100vh;
  width: 100%;
  display: inline-block !important;
  transition: all 0.3s linear;
  z-index: 1;
}

.MapKerentanan {
  height: 30vh;
  width: 100%;
  display: inline-block !important;
  transition: all 0.3s linear;
  z-index: 1;
}

.leftSidebarDataNone {
  display: none;
}

.layerspan {
  margin-left: 9px;
  margin-top: 8px;
  position: absolute;
  font-size: 14px;
}

.layericon {
  position: absolute;
  margin-top: 8px;
  margin-left: 42px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #00a6a6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

.sidebarLeft {
  width: 300px;
  height: 100vh;
  display: inline-block;
  position: absolute;
  z-index: 3;
  background-color: #00a6a6;
  color: white;
  transition: all 0.3s linear;
  /*   
  overflow-y: scroll;
  overflow-x: hidden; */
}

.sidebarLeftNone {
  background-color: #00a6a6;
  transition: all 0.3s linear;
  width: 0px;
  height: 100%;
  display: inline-block;
  position: absolute;
  z-index: 3;
}

.sidebarLeftIcon {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  color: white;
  background-color: #00a6a6;
  width: 70px;
  height: 40px;
  transition: all 0.3s linear;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  z-index: 3;
  cursor: pointer;
}
.sidebarLeftIconUpper {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  color: white;
  background-color: #00a6a6;
  width: 120px;
  height: 60px;
  transition: all 0.3s linear;
  position: absolute;
  top: 40%;
  bottom: 0;
  left: 0;
  z-index: 4;
  cursor: pointer;
}

.sidebarLeftIconActive {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  width: 70px;
  height: 40px;
  background-color: #00a6a6 !important;
  color: white;
  transition: all 0.3s linear;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 300px;
  z-index: 3;
  /* right:200px; */
  cursor: pointer;
}

.sidebarLeftIconUpperActive {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  width: 120px;
  height: 60px;
  background-color: #00a6a6 !important;
  color: white;
  transition: all 0.3s linear;
  position: absolute;
  top: 43%;
  bottom: 0;
  left: 300px;
  z-index: 4;
  /* right:200px; */
  cursor: pointer;
}

.sidebarLeftLayerList {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 138px);
}

.sidebarRight {
  width: 400px;
  height: 100vh;
  display: inline-block;
  overflow: auto;
  position: absolute;
  z-index: 99;
  transition: all 0.3s linear;
  background-color: white;
  right: 0;
  top:0;
}

.sidebarRightNone {
  background-color: white;
  transition: all 0.3s linear;
  width: 0px;
  height: 100%;
  display: inline-block;
  position: absolute;
  z-index: 3;
}

.sidebarRightIconPos {
  margin-top: 13px;
}

.sidebarRightIcon {
  font-size: 35px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  color: #00a6a6;
  background-color: rgba(255, 255, 255, 0.7);
  width: 38px;
  height: 60px;
  /* padding: 5px; */
  padding-left: 10px;
  transition: all 0.3s linear;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: -48px;
  z-index: 3;
  /* right:200px; */
  cursor: pointer;
}

.sidebarRightIcon:hover {
  background-color: rgba(255, 255, 255, 1);
}

.sidebarRightContent {
  padding: 20px 20px 10px 20px;
  font-size: 12px;
  background-color: white;
}

.sidebarRightContentNone {
  display: none;
}

.sidebarRightContentTitle {
  font-family: 'Poppins SemiBold', 'Poppins Regular', 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #00a6a6;
  margin-bottom: 15px;
}

.sidebarRightContentTingkatJenis {
  color: black;
  font-style: normal;
  font-size: 12px;
  line-height: 38px;
}

.sidebarLeftContent {
  padding: 20px 20px 10px 20px;
  font-size: 12px;
}

.sidebarLeftContentTitle {
  font-family: 'Poppins SemiBold', 'Poppins Regular', 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
  color: white;
  margin-bottom: 30px;
}

.sidebarRightContentTingkatJenis {
  color: black;
  font-style: normal;
  font-size: 12px;
  line-height: 38px;
}

.footerHome {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  z-index: 3 !important;
  border-width: 0px;
  position: absolute;
  width: 500px;
  height: 30px;
  background: inherit;
  background-color: rgba(0, 166, 166, 0.6);
  border: none;
  border-radius: 250px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: none;
  /* margin-top: 10px; */
  color: white;
  font-size: 12px;
  font-weight: 400;
}

.selectHome {
  position: absolute !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3 !important;
}

.SelectCari {
  margin-right: 10px;
  border-radius: 30px !important;
  height: 70px;
  border-bottom-right-radius: 10px !important;
  font-weight: 400 !important;
}

.ButtonCari {
  margin-left: 25px !important;
  margin-top: 15px !important;
  color: white !important;
  padding: 7px !important;
  background-color: #ff6b09 !important;
}

.ButtonReset {
  margin-left: 15px !important;
  margin-top: 15px !important;
  color: white !important;
  padding: 7px !important;
  background-color: red !important;
}

.LegendCms {
  background-color: #00a6a6;
  font-size: 12px;
  color: #ffffff;
  width: 100%;
  height: 20px;
  display: block;
  padding: 5px;
  margin-left: -5px;
  margin-top: -7px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.TextLegendJudul {
  font-size: 15px !important;
  font-weight: bold;
  margin-left: -20px !important;
}

.TextLegend {
  font-size: 14px !important;
  margin-left: -20px !important;
}

.TextInfo {
  font-size: 14px !important;
  margin-left: -20px !important;
}

.IconInfoLegendHeader {
  font-size: 20px !important;
  margin-left: 19px !important;
}

.IconInfo {
  font-size: 13px !important;
  margin-left: 30px !important;
  margin-bottom: 3px !important;
  margin-top: 3px;
}

.IconLegend {
  font-size: 20px !important;
  margin-left: 20px !important;
  margin-bottom: 3px !important;
}

.BoxLegend {
  background: #f7f9f9;
  padding: 5px;
  float: right;
  min-width: 200px;
  margin-bottom: 15px !important;
  border-radius: 9px;
  margin-top: 10px;
  margin-left: 30px;
}

.BoxWilayah {
  background: #f7f9f9;
  padding: 5px;
  height: 120px;
  width: 200px;
  /* margin-bottom: 7px !important; */
  margin-left: 30px;
  border-radius: 9px;
}

/* Admin Daerah Profile */

.headerCard {
  background-color: #00a6a6;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  padding: 3px 0px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.CardProfileRingkasan {
  border-radius: 20px !important;
  width: 100%;
  height: 97%;
  max-width: 100%;
  margin: 40px 0px;
}

.ProfilePic {
  border-radius: 50%;
  /* border: 1px solid #524949 !important; */
  box-shadow: 1px 1px #a9a0a0;
  width: 100px;
  height: 100px;
  font-size: 50px !important;
  margin: 10px 90px !important;
}

.nameProfile {
  font-size: 50px !important;
  text-align: center !important;
  margin-left: 40px !important;
}

.editProfile {
  left: 10px;
  top: 30px;
  color: #ff6b09 !important;
  background-color: #ffffff !important;
  border: 1px solid #f2711b !important;
  width: 40px !important;
  height: 40px !important;
}

.namaAdminDaerah {
  text-align: center;
  font-size: 14px !important;
  font-weight: bold !important;
}

.emailAdminDaerah {
  text-align: center;
  font-size: 13px !important;
  font-style: italic;
  margin-bottom: 15px !important;
}

.TreeView {
  margin: 0px 10px !important;
  margin-bottom: 20px !important;
}

.TreeItem {
  margin: 10px 0px !important;
  color: black !important;
  font-size: 14px !important;
}

.TreeItemColor {
  color: #00a6a6 !important;
}

.JudulIsiRingkasan {
  font-size: 16px !important;
  margin-left: 25px !important;
  color: #00a6a6 !important;
  font-weight: bold !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.IsiRingkasan {
  margin-left: 50px !important;
  font-size: 14px !important;
}

.ditambahkanCard {
  box-shadow: 5px 5px 15px rgba(185, 185, 185, 0.349019607843137);
  padding: 5px 5px;
  border-radius: 10px !important;
  margin-left: 50px !important;
  margin-bottom: 10px !important;
}

.IconFileDitambahkan {
  font-size: 13px !important;
  color: #00a6a6 !important;
}

.ditambakanPadaTgl {
  float: right !important;
  /* margin-top: 3px !important; */
  font-size: 12px !important;
  color: #9d9d9d !important;
}

.dataKerentanan {
  font-size: 12px !important;
  color: #00a6a6 !important;
  margin-left: -10px;
  margin-top: -4px !important;
  padding: 10px 10px !important;
}

.buttonLihatData {
  border-color: #f2711b !important;
  color: #f2711b !important;
  border-radius: 30px !important;
  width: 100% !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
}

.UnduhData {
  font-size: 12px !important;
  color: #00a6a6 !important;
  font-weight: bold !important;
  margin-top: 3px !important;
  text-decoration: underline;
  font-family: 'Poppins !important';
  text-align: center;
  cursor: pointer;
}

.lihatDataSemua {
  float: right !important;
  cursor: pointer;
  border: none;
  font-size: 14px !important;
  background-color: transparent;
  color: #00a6a6 !important;
  font-weight: bold !important;
  margin-top: 3px !important;
  text-decoration: underline;
  margin-bottom: 20px !important;
}

.closeButtonDialog {
  float: right !important;
  /* margin-left: -30px !important; */
  /* border-radius: 40px !important; */
  font-size: 12px !important;
}

.dialogPopUp {
  border-top-right-radius: 20px !important;
  border: 2px solid white !important;
  border-radius: 20px !important;
}

.dialogPopUpConnten {
  overflow: auto;
  margin: 0px 30px;
  margin-bottom: 20px;
}

.divDataBahayaJudul {
  margin-left: 50px !important;
}

.DivDataBahayaPopup {
  margin: 0px 10px;
  overflow: inherit !important;
}

.IsiDataBahayaPopup {
  margin-top: -150px;
  width: 450px !important;
  box-shadow: 2px 2px 2px 2px rgb(201, 182, 182) !important;
}

.chartTersimpan {
  max-width: 113% !important;
  max-height: 113% !important;
  margin-top: 10px !important;
  margin-bottom: 3px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  border: 1px solid rgb(196, 189, 189) !important;
  border-radius: 10px !important;
}

.divKeteranganBahayaTersimpan {
  margin-top: 13px !important;
}

.KetBahaya1 {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #00a6a6 !important;
}

.KetBahaya2 {
  font-size: 12px !important;
  color: #00a6a6 !important;
}

.KetBahaya3 {
  margin-bottom: 17px !important;
  font-size: 12px !important;
  color: #00a6a6 !important;
}

.KetBahaya4 {
  font-size: 12px !important;
  margin-top: 7px !important;
}

.IconPrintBahayaTersimpan {
  margin-top: 10px !important;
  margin-right: 3px !important;
  float: right !important;
  color: #f2711b !important;
  border: #f2711b !important;
}

.IconPrintBahayaTersimpans {
  margin-left: 5px !important;
  color: #f2711b !important;
  border: #f2711b !important;
  padding: 0px !important;
}

.IconMenuBahayaTersimpan {
  margin-right: -20px;
  float: right !important;
  color: transparent !important;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

.IconMenuBahayaTersimpan:hover {
  color: #f2711b !important;
}

.JudulIsiRingkasanValidasiData {
  margin-left: 25px !important;
  color: #00a6a6 !important;
  font-weight: bold !important;
  margin-top: 30px !important;
  margin-bottom: 10px !important;
}

.TableValidasiBahaya {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
}

.TableValidasiBahaya td,
.TableValidasiBahaya th {
  border: 1px solid rgb(0, 0, 0);
  padding: 3px 18px !important;
  text-align: center;
  font-size: 12px !important;
  font-family: 'Poppins', sans-serif;
}

.TableValidasiBahaya th {
  /* padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 30px !important;
  padding-right: 30px !important; */
  text-align: center !important;
  background-color: #c4fbf2 !important;
  color: black !important;
  font-weight: normal !important;
  font-family: 'Poppins', sans-serif;
}

.TablePilihanAdaptasi {
  border-collapse: collapse;
  width: 100%;
  overflow-x: scroll;
}

.TablePilihanAdaptasi td,
.TablePilihanAdaptasi th {
  border: 1px solid rgb(0, 0, 0);
  /* padding: 3px 18px !important; */
  text-align: center;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif;
}

.TablePilihanAdaptasi th {
  /* padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 30px !important;
  padding-right: 30px !important; */
  padding: 3px 29px !important;
  text-align: center !important;
  background-color: #1cf4d5 !important;
  color: black !important;
  font-weight: none;
  font-family: 'Poppins', sans-serif;
}

.RecentActivities {
  border: 1px solid #00a6a6;
  height: 80vh !important;
  border-radius: 20px !important;
  margin: 10px 10px;
  overflow: scroll;
}

.TextRecentActivities {
  margin: 20px 20px;
}

.TextRecentActivitiesTypo {
  color: #00a6a6 !important;
  font-weight: bold !important;
  text-align: center !important;
}

.TextRecentActivitiesBorder {
  border: 1px solid #bbbbbb !important;
  border-radius: 30px !important;
  width: 120px;
  margin: 7px 0px;
  text-align: center;
}

.TextRecentActivitiesSPan {
  color: #bbbbbb;
  font-size: 12px;
  /* margin: 35px 35px; */
}

.IconToday {
  font-size: 12px !important;
  margin-right: 5px;
  margin-top: 5px;
  position: relative;
}

.textToday {
  font-size: 12px !important;
}

.labelToday {
  font-size: 12px !important;
  color: #bbbbbb;
}

.activityContainer {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.marginToday {
  margin-left: 20px !important;
}

/* Daftar Data Kerentanan */

.CardDataKerentanan {
  border-radius: 20px !important;
  min-height: 60vh;
}

.DivJudulDataKerentanan {
  margin: 20px 20px;
}

.JudulDataKerentanan {
  color: #00a6a6 !important;
  font-weight: bold !important;
  font-size: 16px !important;
  margin-right: 40px !important;
}

.IconJudul {
  color: #00a6a6 !important;
  margin-top: 5px !important;
}

.buttonUploadData {
  margin-left: 60px !important;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  text-transform: capitalize !important;
  background-color: #f2711b !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  padding: 3px 13px !important;
}

.paperSearch {
  padding: 3px 0px 3px 0px !important;
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  height: 24px !important;
  border: 1px solid white !important;
  /* margin-top: -3px !important; */
  /* margin-left: -5px; */
  border-radius: 20px !important;
  font-size: 12;
  background-color: rgba(0, 166, 166, 0.9) !important;
  box-shadow: none;
  margin-bottom: 30px;
}

.iconButton {
  /* padding: 10; */
  font-size: 12px !important;
  /* margin-right: -9px !important; */
  background-color: transparent;
  color: white !important;
}

.inputSearch {
  /* margin-left: 1px; */
  flex: 1;
  font-size: 12px !important;
  padding-left: 10px;
  font-weight: 300;
  background-color: transparent;
  color: white !important;
}

.divUnduhFormatData {
  float: right !important;
  color: #00a6a6 !important;
  cursor: pointer;
}

.IconUnduhFormatData {
  font-size: 12px !important;
}

.TextUnduhFormatData {
  font-size: 14px !important;
  font-family: 'Poppins';
  font-weight: bold !important;
  text-decoration: underline !important;
}

.TablePilihanDataKerentanan {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  font-size: 12px !important;
}

.TablePilihanDataKerentanan td,
.TablePilihanDataKerentanan th {
  border: 1px solid #ddd;
  padding: 8px;
}

.TablePilihanDataKerentanan tr:nth-child(even) {
  background-color: #f2f2f2;
}

.TablePilihanDataKerentanan tr:hover {
  background-color: #ddd;
}

.TablePilihanDataKerentanan th {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
  background-color: #caecec;
  color: #000000;
  font-weight: normal !important;
}

.IconDocTable {
  font-size: 13px !important;
  color: #00a6a6 !important;
  margin-right: 3px !important;
}

.TdNamaFile {
  color: #00a6a6 !important;
  font-size: 12px !important;
}

.openingData {
  float: right !important;
  color: #00a6a6 !important;
  font-size: 12px !important;
  margin-right: 30px !important;
}

.IconTableTindakanDiv {
  color: transparent !important;
  cursor: pointer;
}

.IconTableTindakanDiv:hover {
  color: #f2711b !important;
}

.IconTableTindakan {
  font-size: 13px !important;
  margin-left: 5px;
  margin-right: 5px;
  color: #f2711b !important;
}

.AppBarDataKerentanan {
  background-color: transparent !important;
  border-color: #979797 !important;
  color: #000000 !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  box-shadow: 0 4px 20px 0 transparent, 0 7px 10px -5px transparent;
  font-size: 12px !important;
}

.tabDataKerentanan {
  padding: 0px 0px;
}

.divDataUploadDataJudul {
  margin-top: -30px !important;
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.dialogPopUpConntenDataUpload {
  text-align: center;
  margin-bottom: 20px !important;
}

.UnduhFormatdataExcel {
  font-size: 12px !important;
  color: #f2711b !important;
  text-decoration: underline !important;
  float: right !important;
  margin-bottom: 30px !important;
  cursor: pointer;
}

.dialogPopUpDataUpload {
  border: 2px solid white !important;
  border-radius: 20px !important;
}

.JudulDialogUploadData {
  color: #00a6a6 !important;
  font-size: 14px;
  font-weight: bold !important;
}

.downloadformat {
  color: #f2711b !important;
  font-size: 12px;
  float: right;
  cursor: pointer !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
}

.JudulSubDialog {
  color: #a8a8a8 !important;
  font-size: 14px;
  font-weight: bold !important;
}

/* Edit Profil */

.CardEditProfil {
  border-radius: 20px !important;
  max-height: 100% !important;
}

.CardEditProfilAdminProgram {
  border-radius: 20px !important;
  max-height: 100% !important;
  max-width: 50% !important;
  margin: 0px 296px !important;
}

.JudulFilename {
  font-size: 12px !important;
  text-align: left !important;
}

.EditProfil {
  color: #00a6a6 !important;
  font-size: 16px !important;
  font-weight: bold !important;
  /* margin-left: 20px !important;
  margin-top: 30px !important; */
}

.DivJudulEditUbah {
  margin-left: 20px !important;
  margin-top: 30px !important;
  margin-bottom: 20px !important;
}

.borderLabel {
  height: 100% !important;
  width: 100% !important;
  border: 1px solid #797979 !important;
  border-radius: 10px !important;
}

.LabelEditProfil {
  font-size: 14px !important;
  width: 129px !important;
  margin-top: -11px !important;
  margin-left: 13px !important;
  color: #797979 !important;
  background: white !important;
}

.LabelEditProfilAkun {
  font-size: 14px !important;
  width: 96px !important;
  margin-top: -11px !important;
  margin-left: 13px !important;
  color: #797979 !important;
  background: white !important;
}

.IconEditProfil {
  border-radius: 50%;
  border: 1px solid #524949 !important;
  /* box-shadow: 1px 1px #a9a0a0; */
  width: 100px;
  height: 100px;
  font-size: 50px !important;
  /* margin: 10px 90px !important; */
}

.nameEditProfil {
  font-size: 50px !important;
  text-align: center !important;
  color: black !important;
  font-size: 16px !important;
}

.ButtonCopot {
  margin-top: 7px !important;
  font-size: 12px !important;
  color: #f2711b !important;
  border-radius: 20px !important;
  border: 1px solid #f2711b !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  max-width: 30px !important;
  padding: 7px 63px !important;
}

.ButtonUbah {
  margin-top: 5px !important;
  font-size: 12px !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  border: 1px solid #f2711b !important;
  background-color: #f2711b !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  max-width: 30px !important;
  padding: 7px 63px !important;
}

.InputInformasiAkun {
  width: 100% !important;
  font-size: 12px !important;
}

.InputInformasiAkun {
  margin-bottom: 10px !important;
}

.btnSaveValidasi {
  color: white !important;
  width: 70% !important;
  background: #f2711b !important;
  border-color: #f2711b !important;
  border-radius: 30px !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  margin-top: 300px;
}

.TypographyUbahPassword {
  margin-bottom: 20px !important;
  text-align: right !important;
  text-decoration: underline !important;
  font-style: italic !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #00a6a6 !important;
}

.TypographyUbahPassword:hover {
  margin-bottom: 20px !important;
  text-align: right !important;
  color: #0c4479 !important;
}

.dialogPopUpUbahPassword {
  border-radius: 20px !important;
}

.submitUbahPassword {
  font-size: 12px !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  border: 1px solid #f2711b !important;
  background-color: #f2711b !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  max-width: 30px !important;
  padding: 7px 63px !important;
  margin-top: 7px !important;
}

.LabelEditProfilInformasiDinas {
  font-size: 14px !important;
  width: 99px !important;
  margin-top: -11px !important;
  margin-left: 13px !important;
  color: #797979 !important;
  background: white !important;
}

.TypographyInformasiAkun {
  color: #00a6a6 !important;
  margin-bottom: 10px !important;
}

.uploadInputTitle {
  color: #00a6a6 !important;
  /* margin-bottom: 10px !important; */
  font-size: 12px !important;
  float: left;
}

.uploadInputIcon {
  color: #00a6a6 !important;
  /* margin-bottom: 10px !important; */
  font-size: 12px !important;
}

.uploadInputText {
  width: 100% !important;
  font-size: 12px !important;
}

.uploadInputText2 {
  width: 100% !important;
  font-size: 12px !important;
  border: 1px solid;
  border-radius: 10px;
  padding: 1px 5px 1px 5px;
}

.iconSelector {
  width: 50px !important;
  font-size: 12px !important;
  border: 1px solid;
  border-radius: 10px;
  padding: 1px 5px 1px 5px;
}

.uploadRadioChoice {
  float: left;
  font-size: 12px;
  color: #00a6a6;
  /* margin-top: 20px; */
}

.GridContainerFasyankes {
  margin-bottom: 10px !important;
}

.TypografiJumlahFasyankes {
  color: #00a6a6 !important;
  font-weight: bold !important;
  font-size: 12px !important;
  margin-bottom: 15px !important;
  margin-top: -5px !important;
}

.InputJumlahFasyankes {
  margin-bottom: 5px !important;
  width: 65px !important;
  height: 25px !important;
  border: 1px solid #a8a8a8;
  border-radius: 5px !important;
  text-align: center !important;
}

.LabelFasyankes {
  margin-bottom: 30px !important;
  font-size: 12px !important;
}

.GridContainerFasyankes {
  margin-bottom: 30px !important;
}

.TypografiPrioritas {
  color: #00a6a6 !important;
  font-weight: bold !important;
  font-size: 12px !important;
  margin-bottom: 5px !important;
}

.labelForm {
  font-size: 12px !important;
}

.inputGeografis {
  width: 80% !important;
  text-align: center;
  margin-bottom: 5px !important;
  height: 25px !important;
  border: 1px solid #a8a8a8;
  border-radius: 5px !important;
  text-align: center !important;
}

.submmiteditProfilAdminDaerah {
  margin: 10px 130px !important;
  background-color: #f2711b !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  width: 130px !important;
}

.submmiteditProfilAdminProgram {
  /* margin: 10px 170px !important; */
  background-color: #f2711b !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  /* font-size: 16px !important; */
  width: 130px !important;
}

.divKonfirmasiTindakan {
  margin: 10px 20px !important;
}

.divButtonKonfirmasi {
  margin: 0px 30px !important;
}

.buttonKonfirmasiTidak {
  border: 1px solid #df2121 !important;
  color: #df2121 !important;
  border-radius: 7px !important;
  width: 40px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  margin-right: 10px !important;
}

.buttonKonfirmasiYa {
  border: 1px solid #21a5df !important;
  color: #21a5df !important;
  border-radius: 7px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
}

.buttonLogin {
  border: 1px solid #f2711b !important;
  color: #ffff !important;
  border-radius: 7px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  background-color: #f2711b !important;
  border-radius: 30px !important;
  width: 80px !important;
  padding: 2px 8px !important;
  margin: 5px !important;
}

.buttonRegister {
  border: 1px solid #f2711b !important;
  color: #f2711b !important;
  border-radius: 7px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  border-radius: 30px !important;
  width: 80px !important;
  padding: 2px 8px !important;
  margin: 5px !important;
}

/* adminDaerah Profile */

.TableDataSaya {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 90%;
  overflow-x: auto;
}

.TableDataSaya td,
.TableDataSaya th {
  border: 1px solid rgb(0, 0, 0);
  padding: 3px 18px !important;
  text-align: center;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif;
}

.TableDataSaya th {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
  background-color: #1cf4d5 !important;
  color: black !important;
  font-weight: none !important;
  font-family: 'Poppins', sans-serif;
}

.TableDataSaya {
  border-collapse: collapse;
  width: 100%;
  overflow-x: scroll;
}

.TableDataSaya td,
.TableDataSaya th {
  border: 1px solid rgb(0, 0, 0);
  /* padding: 3px 18px !important; */
  text-align: center;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif;
}

.TableDataSaya th {
  /* padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 30px !important;
  padding-right: 30px !important; */
  padding: 0px 0px !important;
  text-align: center !important;
  background-color: #1cf4d5 !important;
  color: black !important;
  font-weight: none;
  font-family: 'Poppins', sans-serif;
}

.AprvTh {
  padding: 0px 5px !important;
}

.DataSaya {
  border: 1px solid #00a6a6;
  height: 100% !important;
  width: 100% !important;
  border-radius: 20px !important;
  margin: 10px 10px;
}

.JudulDataSaya {
  margin-left: 10px !important;
  color: #00a6a6 !important;
  font-weight: bold !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  display: flex;
  align-items: center;
}

.downloadFormatData {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #00a6a6 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  text-align: right;
  padding: 0 16px;
}

.uploadButtonadminProgram {
  background-color: #f2711b !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  padding: 5px 40px !important;
  margin-top: 10px !important;
  margin: 20px 40% 0px 40% !important;
}

.cardHutan {
  width: 100%;
  height: 250px;
  background-color: #054737;
  border: 2px solid white;
  border-radius: 4px;
}

.btnHutan {
  color: white !important;
}

.btnHutan:hover {
  opacity: 1 !important;
}

.imgHutan {
  width: 20%;
  height: 20%;
}

.container {
  position: relative;
  width: 100%;
  max-width: 100%;
  opacity: 0.7;
  max-height: 100%;
}

.container:hover {
  opacity: 2;
}

.container .btnHutan {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: Transparent;
  color: rgb(0, 0, 0);
  font-size: 19px;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  opacity: 1;
}

/* Apps */

.cardApps {
  height: 100%;
}

.containerApps {
  position: relative;
  width: 100%;
  max-width: 100%;
  opacity: 0.7;
  max-height: 100%;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: Transparent;
  color: rgb(0, 0, 0);
  font-size: 19px;
  font-weight: bold;
  padding: 12px 0px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  opacity: 1;
}

.containerApps:hover {
  opacity: 2;
  background-color: #054737;
  border-radius: 4px;
  color: honeydew;
}

.Icon {
  font-size: 50px !important;
}

/* Artikel Berita dan Video */

.video {
  margin-top: 30px;
}

.h4AB {
  font-weight: bold;
}

.gambarArBe {
  max-height: 100px;
  max-width: 100px;
}

.chartAB {
  margin-top: -20px !important;
}

/* Footer */

.search__input {
  margin-top: 10px;
}

.subscribe {
  height: 40px;
  margin-left: 3px !important;
}

/* .inputEmail{
width: 300px !important;
height: 40px !important;
 background-color: white;

 
} */

.footerInner {
  margin-left: 90px !important;
}

/* Register */

.registerBody {
  width: 70% !important;
  position: relative !important;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 5% !important;
}

.cBodyRegister {
  margin-bottom: 20px;
  margin-top: 10px;
}

.spinnerMap {
  position: absolute;
  /*Can also be `fixed`*/
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  left: 0;
}

.cBodyRegisterBot {
  margin-bottom: 20px;
}

.btnDaftar {
  width: 100% !important;
  background-color: #054737 !important;
  color: white !important;
  margin-bottom: 15px !important;
}

.btnKembali {
  width: 100% !important;
  background-color: white !important;
  color: black !important;
}

/* Login */

.login {
  width: 50% !important;
  position: relative !important;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 5% !important;
  /* margin-bottom: 30%; */
  /* margin-top: 30%px !important; */
}

/* Informasi Program  & Survei Online*/

/* .searchBox {
  width: 100%;
  margin-bottom: 10px;
  height: 36px;
  box-sizing: border-box;
  border: 2px solid #054737;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 10px;
  background-color: #054737;
  color: white !important;
  padding: 0px 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */

.modal {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.closeWindows {
  float: right;
  max-width: 20px;
  max-height: 20px;
  margin-top: 5px;
  cursor: pointer;
}

.dialogConText {
  width: 100%;
}

.marginInput {
  margin-bottom: 20px;
  margin-top: 10px;
}

.gridcontainerBotom {
  margin-bottom: 30px !important;
}

.widthInput {
  width: 100%;
}

.helperText {
  color: red;
}

.buttonUpload {
  /* float: right; */
  background-color: rgb(11, 88, 204) !important;
  color: white !important;
}

.buttonDelete {
  /* float: right; */
  background-color: red !important;
  color: white !important;
  min-width: 85px;
}

.buttonEdit {
  /* float: right; */
  background-color: #054737 !important;
  color: white !important;
  margin-right: 15px !important;
  min-width: 85px;
}

.buttonConfirm {
  /* float: right; */
  background-color: #054737 !important;
  color: white !important;
}

.buttonClose {
  /* float: right; */
  background-color: white !important;
  color: black !important;
  margin-right: 20px !important;
}

::placeholder {
  color: white;
}

.selectEmpty {
  margin-top: 0px;
  width: 100% !important;
}

.paper {
  background-color: white;
  /* box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.8); */
  padding: 10px;
  border-radius: 7px;
}

.containerDate {
  display: flex;
  flex-wrap: wrap;
}

.textFieldDate {
  /* marginLeft: theme.spacing(1);
    marginRight: theme.spacing(1); */
  width: 200;
}

.input {
  display: none;
}

/* Artikel */

.judulArtikel {
  font-weight: 400;
}

.isiArtikel {
  max-height: 300px;
  text-align: justify;
}

.pinggiranArtikel {
  max-height: 300px;
}

.judulPinggir {
  font-weight: 500;
  font-size: medium;
}

@media only screen and (max-width: 576px) {
  .ContainerEditProfil {
    margin: 0px 0px !important;
  }
  /*Artikel  */
  .pinggiranArtikel {
    width: auto !important;
  }
  .judulPinggir {
    font-weight: 500;
    font-size: medium;
  }
  /* akhir Artikel */
  .registerBody {
    width: 100% !important;
    position: relative !important;
    margin: 0 !important;
    margin-top: 50px !important;
  }
  .login {
    width: 100% !important;
    position: relative !important;
    margin: 0 !important;
    margin-top: 50px !important;
  }
  .justify {
    justify-content: end;
  }
  .search__input {
    margin-top: 10px;
  }
  .subscribe {
    margin-left: 20px !important;
    margin-top: 5px !important;
  }
  .inputEmail {
    margin-left: 20px !important;
  }
  .footerInner {
    margin-left: 90px !important;
  }
  .video {
    width: 100%;
    height: 300px !important;
  }
  .gambarArBe {
    max-width: 100% !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 640px) {
  .register {
    width: 100% !important;
    position: relative !important;
    margin: 0 !important;
    margin-top: 50px !important;
  }
  .login {
    width: 100% !important;
    position: relative !important;
    margin: 0 !important;
    margin-top: 50px !important;
  }
  .justify {
    justify-content: end;
  }
  .search__input {
    margin-top: 10px;
  }
  .subscribe {
    margin-left: 20px !important;
    /* margin-top: 5px !important; */
  }
  .inputEmail {
    margin-left: 20px !important;
  }
  .footerInner {
    margin-left: 90px !important;
  }
  .video {
    width: 100%;
    height: 300px !important;
  }
  .gambarArBe {
    max-width: 100% !important;
    height: auto !important;
  }
}

.checkboxTextFormat {
  font-family: 'Poppins SemiBold', 'Poppins Regular', 'Poppins', sans-serif !important;
  font-weight: 300;
  font-style: normal;
  color: white !important;
  font-size: 12px !important;
  margin: 0;
  padding: 5px;
  padding-left: 7px;
  margin-top: 6px;
}

.checkboxTextFormatTitle {
  font-family: 'Poppins SemiBold', 'Poppins Regular', 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  font-style: normal;
  color: white !important;
  font-size: 12px !important;
  margin: 0;
  padding: 5px;
}

.validasiQuestion {
  font-weight: 600;
  margin-bottom: 5px;
}

.validasiInput {
  width: 30px;
  padding: 5px;
}

.validasiButton {
  border: 1px solid;
  padding: 1px 11px;
  width: 90px;
  border-radius: 10px;
}

.registerInputContainer {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px !important;
}

.registerInputLabel {
  font-size: 12px !important;
  color: #00a6a6 !important;
}

.hasaccount {
  font-size: 12px;
  color: #b7b8b8;
}

.successRegIcon {
  font-size: 100px;
  text-align: center;
  color: #00a6a6;
  margin-bottom: 30px;
}

.successRegTitle {
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
}

.successRegText {
  text-align: center;
  font-size: 12px;
  margin-bottom: 20px;
}

.adminProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
